.root {
  border: 0;
  border-color: transparent;
  background-color: transparent;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  white-space: nowrap;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
}
.root:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  display: block;
  flex: none;
}

.variant--filled {
  border-width: 1px;
  border-style: solid;
}
.variant--filled.color--primary {
  background-color: var(--theme-color-primary);
  border-color: var(--theme-color-primary);
  color: white;
}
.variant--filled.color--secondary {
  background-color: var(--theme-color-gray-100);
  border-color: var(--theme-color-gray-100);
  color: var(--theme-color-gray-800);
}
.variant--filled.color--danger {
  background-color: var(--theme-color-error);
  border-color: var(--theme-color-error);
  color: white;
}

.variant--outlined {
  border-width: 1px;
  border-style: solid;
}
.variant--outlined.color--primary {
  border-color: var(--theme-color-primary);
  color: var(--theme-color-primary);
}
.variant--outlined.color--secondary {
  border-color: var(--theme-color-gray-800);
  color: var(--theme-color-gray-800);
}
.variant--outlined.color--danger {
  border-color: var(--theme-color-error);
  color: var(--theme-color-error);
}

.variant--text {
  color: var(--theme-color-gray-800);
}
.variant--text.color--primary:hover {
  color: var(--theme-color-primary);
}
.variant--text.color--secondary:hover {
  color: var(--theme-color-gray);
}
.variant--text.color--danger:hover {
  color: var(--theme-color-error);
}

.variant--icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-color-gray);
  border-radius: var(--theme-border-radius-full);
  padding: 0 !important;
  line-height: 0 !important;
}

.variant--icon .label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.variant--icon.color--primary:hover {
  color: var(--theme-color-primary);
}
.variant--icon.color--secondary:hover {
  color: var(--theme-color-gray-800);
}
.variant--icon.color--danger:hover {
  color: var(--theme-color-error);
}

.size--sm {
  padding: 0 var(--theme-size-3);
  font-size: var(--theme-font-size-sm);
  min-width: var(--theme-size-9);
  height: var(--theme-size-9);
  line-height: var(--theme-size-9);
  border-radius: var(--theme-border-radius-lg);
  font-weight: var(--theme-font-weight-semibold);
}
.size--md {
  padding: 0 var(--theme-size-4);
  min-width: var(--theme-size-10);
  height: var(--theme-size-10);
  line-height: var(--theme-size-10);
  border-radius: var(--theme-border-radius-lg);
  font-weight: var(--theme-font-weight-semibold);
}
.size--lg {
  padding: 0 var(--theme-size-8);
  min-width: var(--theme-size-12);
  height: var(--theme-size-12);
  line-height: var(--theme-size-12);
  border-radius: var(--theme-border-radius-lg);
  font-weight: var(--theme-font-weight-semibold);
}

.iconPlacement--right .wrap {
  flex-direction: row-reverse;
}
.iconPlacement--right .icon + .label {
  margin-right: var(--theme-size-2);
}

.iconPlacement--left .icon + .label {
  margin-left: var(--theme-size-2);
}

.iconPlacement--fixedRight .icon {
  position: absolute;
  right: 0;
}
.iconPlacement--fixedLeft .icon {
  position: absolute;
  left: 0;
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}
.variant--text.isDisabled,
.variant--icon.isDisabled {
  color: var(--theme-color-gray);
}

.isLoading .wrap {
  visibility: hidden;
}

.spinner {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root {
  width: 100%;
  padding: 0 var(--theme-size-3);
  height: var(--theme-size-10);
  display: flex;
  align-items: center;
  color: var(--theme-color-gray-800);
  border-radius: var(--theme-border-radius-sm);
  background-color: transparent;
  transition: background-color 0.2s;
  border: none;
  cursor: pointer;
}

.root:hover,
.root:focus {
  color: var(--theme-color-gray-800);
  background-color: var(--theme-color-gray-100);
  opacity: 1;
}

.icon {
  margin-right: var(--theme-size-2);
}

.root.isDisabled {
  pointer-events: none;
  opacity: 0.5;
  background-color: transparent;
}

.root {
  outline: none;
  overflow: hidden;
  border-radius: var(--theme-border-radius-md);
  background-color: white;
  color: var(--theme-color-gray);
  border: 1px solid var(--theme-color-gray-100);
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.creator {
}

.price {
  font-weight: var(--theme-font-weight-semibold);
  color: var(--theme-color-gray-800);
}

.cover {
  position: relative;
  overflow: hidden;
  flex: 1;
  background-color: var(--theme-color-gray-50);
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--theme-size-4);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-weight: var(--theme-font-weight-semibold);
  color: var(--theme-color-gray-800);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.details {
  padding: var(--theme-size-4);
}

.priceAndSupply {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.icons {
  display: flex;
  flex: none;
}
.icon {
  border-radius: var(--theme-border-radius-full);
  border: 2px solid white;
}
.icon:hover {
  z-index: 1;
}
.icon:nth-of-type(2) {
  margin-left: calc(var(--theme-size-inverse) * var(--theme-size-3));
}

.supply {
  font-size: var(--theme-font-size-sm);
}

.rarity {
  position: absolute;
  top: var(--theme-size-4);
  left: var(--theme-size-4);
}

.soldOutBadge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: none;
  background: #da1e1e;
  border-color: #da1e1e;
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.root {
  display: block;
}

.size--sm {
  height: var(--theme-size-8);
}

.size--md {
  height: var(--theme-size-12);
}

.size--lg {
  height: var(--theme-size-16);
}

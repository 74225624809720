html,
body {
  overflow-x: hidden;
}

html.no-scroll {
  overflow: hidden;
}

body {
  font-family: var(--theme-font-family);
  font-weight: var(--theme-font-weight);
  font-size: var(--theme-font-size);
  line-height: var(--theme-line-height);
  color: var(--theme-color-gray);
  background-color: white;
}

html.no-scroll body {
  padding-right: var(--scroll-width);
}

* {
  -webkit-tap-highlight-color: transparent;
}

ol,
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: none;
}

button,
[role='button'] {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

a,
a:hover,
a:visited,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  outline: none;
}

tbody tr + tr td {
  border-top: 1px solid var(--theme-color-gray-100);
}

hr {
  border: 0;
  height: 1px;
  background-color: var(--theme-color-gray-200);
}

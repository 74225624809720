.root {
  overflow: hidden;
  border-radius: var(--theme-border-radius-sm);
  background-color: var(--theme-color-gray-50);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.size--sm {
  width: var(--theme-size-12);
  height: var(--theme-size-12);
}

.size--md {
  width: var(--theme-size-16);
  height: var(--theme-size-16);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body {
  background-color: var(--theme-color-gray-50);
}

a {
  color: var(--theme-color-primary);
  text-decoration: none;
}

a:hover {
  color: var(--theme-color-primary-700);
}

.root {
  display: inline-block;
  border-radius: var(--theme-size-20);
  font-weight: var(--theme-font-weight-semibold);
  color: white;
}

.wrap {
  display: flex;
  align-items: center;
  padding: var(--theme-size-1) var(--theme-size-3) var(--theme-size-1)
    var(--theme-size-2);
  gap: var(--theme-size-1);
}

.rarity--unlimited {
  background-color: var(--theme-color-gray-200);
}

.rarity--common {
  background-color: var(--theme-color-gray-300);
}

.rarity--rare {
  background-color: var(--theme-color-gray);
}

.rarity--holo {
  background-color: var(--theme-color-gray);
}

.rarity--platinum {
  background-color: var(--theme-color-gray-50);
  color: var(--theme-color-gray-800);
}

.rarity--unique {
  background: linear-gradient(90deg, #7554f9 0%, #54d2ce 100%);
}

.size--sm {
  font-size: var(--theme-font-size-xs);
  line-height: var(--theme-line-height-xs);
}
.size--md {
  font-size: var(--theme-font-size-sm);
}

.root {
  background-color: var(--theme-color-gray-800);
  color: white;
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
  padding: var(--theme-size-1-5) var(--theme-size-3);
  border-radius: var(--theme-border-radius-full);
  animation: fadein 0.15s forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

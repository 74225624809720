.root {
  display: flex;
  color: var(--theme-color-gray-800);
  align-items: center;
  flex-wrap: nowrap;
  min-width: 0;
}

.wrap {
  overflow: hidden;
}

.name {
  font-weight: var(--theme-font-weight-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  color: white;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  border-radius: var(--theme-border-radius-full);
}

.rarity--unlimited .icon {
  background-color: var(--theme-color-gray-200);
}

.rarity--common .icon {
  background-color: var(--theme-color-gray-300);
}

.rarity--rare .icon {
  background-color: var(--theme-color-gray);
}

.rarity--holo .icon {
  background-color: var(--theme-color-gray);
}

.rarity--platinum .icon {
  background-color: var(--theme-color-gray-50);
  color: var(--theme-color-gray-800);
}

.rarity--unique .icon {
  background: linear-gradient(90deg, #7554f9 0%, #54d2ce 100%);
}

.size--md .name {
}
.size--md .wrap {
  margin-left: var(--theme-size-2);
}

.size--lg .name {
  font-size: var(--theme-font-size-lg);
}
.size--lg .wrap {
  margin-left: var(--theme-size-3);
}
.size--md .icon {
  height: var(--theme-size-9);
  width: var(--theme-size-9);
}

.size--lg .icon {
  height: var(--theme-size-10);
  width: var(--theme-size-10);
}

.root {
  position: relative;
  display: inline-flex;
  font-size: var(--theme-font-size-md);
  line-height: var(--theme-line-height-md);
  user-select: none;
  cursor: pointer;
  align-items: center;
  color: var(--theme-color-gray);
}

.root input {
  position: absolute;
  opacity: 0;
}

.box {
  display: inline-flex;
  width: var(--theme-size-5);
  height: var(--theme-size-5);
  margin-right: var(--theme-size-2);
  border-radius: var(--theme-size-1);
  align-items: center;
  justify-content: center;
  border: 1px solid var(--theme-color-gray-300);
  background-color: white;
  flex-shrink: 0;
}

.box svg {
  height: auto;
}

.root.isChecked .box {
  background-color: var(--theme-color-primary);
  color: white;
}

.root.isDisabled .box {
  border-style: dashed;
}

.root.isInvalid .box {
  --tw-border-opacity: 1;

  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.root.isChecked:not(.isInvalid) .box {
  border: none;
}

.root:not(.isInvalid):focus-within .box {
  border-color: var(--theme-color-primary);
}

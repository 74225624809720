.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: var(--theme-color-gray-50);
}

.centered {
  text-align: center;
}

.header {
  margin-bottom: var(--theme-size-8);
}

.title {
  margin-bottom: var(--theme-size-8);
}

.description {
  margin-top: calc(var(--theme-size-inverse) * var(--theme-size-5));
  margin-bottom: var(--theme-size-8);
}

.footer {
  margin: var(--theme-size-8);
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
  color: var(--theme-color-gray);
}

.footer a span {
  color: var(--theme-color-primary);
}

.footer a span:hover {
  text-decoration: underline;
}

.root {
  outline: none;
  border-radius: var(--theme-border-radius-md);
  background-color: white;
  color: var(--theme-color-gray);
  position: relative;
  overflow: hidden;
  border: 1px solid var(--theme-color-gray-100);
}

.creator {
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--theme-size-4) var(--theme-size-4) var(--theme-size-6);
  margin: 0;
  width: 100%;
  color: white;
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.075) 60%,
    rgba(0, 0, 0, 0.35) 100%
  );
  z-index: 1;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--theme-size-6) var(--theme-size-4) var(--theme-size-4);
  margin: 0;
  width: 100%;
  color: white;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
}

.name {
  font-weight: var(--theme-font-weight-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.username {
  font-size: var(--theme-font-size-sm);
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}

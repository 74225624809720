.message {
  margin-top: calc(var(--theme-size-inverse) * var(--theme-size-4));
  margin-bottom: var(--theme-size-6);
}

.actions {
  text-align: right;
}

.actions > * {
  margin-left: var(--theme-size-4);
}

.root {
  min-height: 100vh;
}

.wrap {
  display: block;
}

@media (min-width: 768px) {
  .wrap {
    display: flex;
  }
}

.body {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .body {
    margin-left: 16rem;
  }
}

@media (min-width: 768px) {
  .header {
    display: none !important;
  }
}

.sidebar {
  display: none;
  position: fixed;
}

@media (min-width: 768px) {
  .sidebar {
    display: flex;
  }
}

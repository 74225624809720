.root {
  display: flex;
  align-items: center;
}

.type--success {
  color: var(--theme-color-success);
}

.type--error {
  color: var(--theme-color-error);
}

.type--info {
  color: var(--theme-color-primary);
}

.root .icon {
  margin-right: var(--theme-size-2);
}

.size--sm {
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
}

.size--md {
  font-size: var(--theme-font-size-md);
  line-height: var(--theme-line-height-md);
}

.size--lg {
  font-size: var(--theme-font-size-lg);
  line-height: var(--theme-line-height-lg);
  font-weight: var(--theme-font-weight-semibold);
}

.root {
  overflow-y: auto;
}

.table {
  width: 100%;
}

.table thead th {
  color: var(--theme-color-gray);
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
  text-align: left;
}

.table tbody {
  color: var(--theme-color-gray);
}

.table thead > * + *,
.table tbody > * + * {
  border-top-width: 1px;
  border-color: var(--theme-color-gray-200);
}

.table tbody td {
  white-space: nowrap;
  padding-top: var(--theme-size-3);
  padding-bottom: var(--theme-size-3);
}

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100vw - (var(--theme-size-4) * 2));
  max-height: calc(100vh - (var(--theme-size-12) * 2));
  opacity: 0;
}

.root.isLoaded {
  animation: fadein 0.3s forwards;
}

.media {
  position: relative;
  flex: none;
  background-color: black;
  border-top-right-radius: var(--theme-border-radius-sm);
  border-top-left-radius: var(--theme-border-radius-sm);
  overflow: hidden;
  max-width: var(--theme-size-md);
}

.details {
  position: relative;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
  overflow: hidden;
  width: 100%;
  border-bottom-right-radius: var(--theme-border-radius-sm);
  border-bottom-left-radius: var(--theme-border-radius-sm);
}

.indicator {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .root {
    flex-direction: row;
    justify-content: center;
  }

  .media {
    width: 60%;
    border-top-right-radius: 0;
    border-top-left-radius: var(--theme-border-radius-sm);
    border-bottom-left-radius: var(--theme-border-radius-sm);
  }

  .details {
    width: 24rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--theme-border-radius-sm);
    border-top-right-radius: var(--theme-border-radius-sm);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  border-bottom: 1px;
  border-bottom-color: var(--theme-color-gray-200);
  border-bottom-style: solid;
}

.isStretched > * {
  flex: 1;
}

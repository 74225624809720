.root {
  border: 1px solid var(--theme-color-gray-200);
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  color: var(--theme-color-gray);
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  font-weight: var(--theme-font-weight-semibold);
  border-radius: var(--theme-border-radius-full);
  transition: all 0.2ms cubic-bezier(0.4, 0, 0.2, 1);
}

.image {
  flex: none;
  background-repeat: norepeat;
  background-size: cover;
  background-position: center;
  border-radius: var(--theme-border-radius-full);
  margin-right: var(--theme-size-2);
}

.variant--primary {
  background-color: white;
  color: var(--theme-color-gray-800);
}
.variant--primary.isActive {
  border-color: var(--theme-color-primary);
  color: var(--theme-color-primary);
}

.size--md {
  padding: 0 var(--theme-size-3);
  height: var(--theme-size-9);
  line-height: var(--theme-size-9);
  font-size: var(--theme-font-size-sm);
}
.size--md .image {
  width: var(--theme-size-7);
  height: var(--theme-size-7);
  margin-left: calc(
    (var(--theme-size-inverse) * var(--theme-size-3)) +
      ((var(--theme-size-9) - var(--theme-size-7)) / 2)
  );
}
.size--md .closeButton {
  width: var(--theme-size-9);
  height: var(--theme-size-9);
  margin-right: calc(var(--theme-size-inverse) * var(--theme-size-3));
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: var(--theme-size-6);
  width: 100%;
}

.label {
  line-height: 1;
  margin-bottom: var(--theme-size-2);
}

.wrapper {
  flex-grow: 1;
  width: 100%;
}

.error {
  color: var(--theme-color-error);
  margin-top: var(--theme-size-1);
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
}

.hint {
  color: var(--theme-color-gray-400);
  margin-top: var(--theme-size-1);
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
  margin-left: auto;
}

.info {
  display: flex;
}

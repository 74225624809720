.root {
  color: var(--theme-color-gray);
  border-radius: var(--theme-border-radius-full);
}

.root:hover {
  color: var(--theme-color-primary);
}

.variant--filled {
  background-color: var(--theme-color-gray-100);
}

.variant--transparent {
  background-color: transparent;
}

.root > span > span {
  display: flex;
  align-items: center;
}

.likesCount {
  padding-right: var(--theme-size-1);
  padding-left: var(--theme-size-0.5);
}

.isLiked {
  color: var(--theme-color-primary);
}

.root {
  display: flex;
  align-items: center;
}

.range {
  margin: var(--theme-size-4) 0;
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
}

.track {
  height: var(--theme-size-1);
  width: 100%;
  background-color: var(--theme-color-gray-200);
  border-radius: var(--theme-border-radius-sm);
}

.thumb {
  height: var(--theme-size-6);
  width: var(--theme-size-6);
  background-color: var(--theme-color-primary);
  border: 4px solid white;
  border-radius: 50%;
  outline: none;
}

.labels {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--theme-size-2);
  color: var(--theme-color-gray);
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
}

.input {
  flex-shrink: 0;
  margin: 0;
  margin-left: var(--theme-size-4);
  width: 40px;
  text-align: center;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hasLabels .range {
  margin-top: 0;
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.root:hover {
  text-decoration: underline;
}

.inline {
  display: inline-flex;
}

.variant--primary {
  color: var(--theme-color-primary);
}

.variant--subtle {
  color: var(--theme-color-gray);
}

.size--sm {
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
}

.size--md {
  font-size: var(--theme-font-size-md);
  line-height: var(--theme-line-height-md);
}

.root svg {
  margin-right: var(--theme-size-2);
}

.root {
  animation: spin 0.5s linear infinite;
}

.root circle {
  stroke: none;
}

.color--primary {
  color: var(--theme-color-primary);
}
.color--success {
  color: var(--theme-color-success);
}
.color--white {
  color: white;
}

.size--sm {
  height: var(--theme-size-6);
  width: var(--theme-size-5);
}
.size--md {
  height: var(--theme-size-9);
  width: var(--theme-size-9);
}
.size--lg {
  height: var(--theme-size-12);
  width: var(--theme-size-12);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.root {
  margin: var(--theme-size-16) 0;
  text-align: center;
  color: var(--theme-color-gray-300);
}

.root svg {
  width: var(--theme-size-9);
  height: auto;
  display: inline-block;
  margin-bottom: var(--theme-size-4);
}

.root p {
  color: var(--theme-color-gray);
}

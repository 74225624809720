.root {
  color: var(--theme-color-gray);
  border-radius: var(--theme-border-radius-full);
}

.root:hover {
  color: var(--theme-color-error);
}

.variant--filled {
  background-color: white;
}

.variant--transparent {
  background-color: transparent;
}

.isHearted {
  color: var(--theme-color-error);
}

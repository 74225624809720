.root {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: calc(var(--theme-size-inverse) * var(--theme-size-4));
  margin-right: calc(var(--theme-size-inverse) * var(--theme-size-4));
}

@media (min-width: 768px) {
  .root {
    border-radius: var(--theme-border-radius-md);
    margin-left: 0;
    margin-right: 0;
  }
}

.root {
  display: block;
  line-height: 0;
}

.size--sm {
  width: var(--theme-size-20);
}

.size--md {
  width: var(--theme-size-28);
}

.size--lg {
  width: var(--theme-size-32);
}

.size--xl {
  width: var(--theme-size-36);
}

.size--2xl {
  width: var(--theme-size-48);
}

.root {
  display: flex;
  align-items: center;
  z-index: 30;
  max-width: 100vw;
  height: 100vh;
  padding: var(--theme-size-14) var(--theme-size-4);
  overflow-x: auto;
}

.root:focus {
  outline: none;
}

.overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
  animation: fadein 0.3s forwards;
}

.closeButton {
  position: fixed;
  right: var(--theme-size-2);
  top: var(--theme-size-2);
  z-index: 30;
  color: white;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  position: relative;
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.input {
  padding-right: var(--theme-size-10);
}

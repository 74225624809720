.root {
  display: flex;
}

.items {
  display: flex;
  align-items: center;
}

.items li {
  margin: 0 var(--theme-size-0-5);
}

.items a {
  outline: none;
  display: block;
  user-select: none;
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  padding: var(--theme-size-2) var(--theme-size-4);
  border-radius: var(--theme-border-radius-sm);
  color: var(--theme-color-gray);
  border: 1px solid transparent;
}

.previous a,
.next a {
  line-height: 0;
  padding: var(--theme-size-3);
  border-radius: 9999px;
  color: var(--theme-color-gray-800);
}

.disabled a {
  pointer-events: none;
  color: var(--theme-color-gray-300);
}

.active a {
  font-weight: var(--theme-font-weight-semibold);
  color: var(--theme-color-gray-800);
  background-color: var(--theme-color-gray-50);
  border-color: var(--theme-color-gray-50);
}

.items a:hover {
  background-color: var(--theme-color-gray-50);
  border-color: var(--theme-color-gray-50);
  color: var(--theme-color-gray-800);
}

.break {
}

.align-center {
  justify-content: center;
}

.align-right {
  justify-content: flex-end;
}

.busy {
  pointer-events: none;
}

.root svg {
  width: var(--theme-size-5);
  height: auto;
}

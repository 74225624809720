.root {
  display: flex;
  align-items: center;
  border-radius: var(--theme-border-radius-sm);
  border: 1px dashed var(--theme-color-gray-200);
  background-color: white;
  margin-top: var(--theme-size-4);
  padding: var(--theme-size-4) 0 var(--theme-size-4) var(--theme-size-2);
  cursor: move;
}

.root:first-of-type {
  margin-top: 0;
}

.handle {
  flex: none;
  color: var(--theme-color-gray);
  margin-right: var(--theme-size-2);
}

.body {
  flex: 1;
}

.root.isDragging {
  opacity: 0.5;
}

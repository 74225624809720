.root {
  position: relative;
  display: inline-flex;
  font-size: var(--theme-font-size-md);
  line-height: var(--theme-line-height-md);
  user-select: none;
  cursor: pointer;
  align-items: center;
  color: var(--theme-color-gray);
}

.root input {
  width: var(--theme-size-5);
  height: var(--theme-size-5);
  margin: 0 var(--theme-size-2) 0 0;
  appearance: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 9999px;
  border: 1px solid var(--theme-color-gray-300);
  background-color: white;
}

.root.isChecked input {
  position: relative;
  background-color: var(--theme-color-primary);
}

.root.isInvalid input {
  --tw-border-opacity: 1;

  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.root.isDisabled input {
  color: var(--theme-color-gray);
  background-color: var(--theme-color-gray-100);
  border-style: dashed;
}

.root.isChecked:not(.isInvalid) input {
  border: none;
}

.root.isChecked input::before {
  position: absolute;
  width: 33.333333%;
  height: 33.333333%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9999px;
  background-color: white;
  content: '';
}

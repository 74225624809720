.root {
  flex-shrink: 0;
  display: inline-block;
  height: auto;
  line-height: 0;
  text-align: center;
}
.size--xs svg {
  width: var(--theme-size-3);
}
.size--sm svg {
  width: var(--theme-size-4);
}
.size--md svg {
  width: var(--theme-size-5);
}
.size--lg svg {
  width: var(--theme-size-6);
}
.size--xl svg {
  width: var(--theme-size-8);
}
.size--2xl svg {
  width: var(--theme-size-10);
}
.size--3xl svg {
  width: var(--theme-size-12);
}

.root svg {
  height: auto;
  display: inline-block;
}

.root {
  overflow: hidden;
  position: relative;
}

.root > :first-child {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.root::before {
  height: 0;
  display: block;
  content: '';
  padding-bottom: calc(100% / var(--aspect-ratio));
}

.body {
  background: white;
  box-shadow: var(--theme-shadow-lg);
  border-radius: var(--theme-border-radius-sm);
  z-index: 50;
  animation: fadein 0.3s forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  display: flex;
  color: var(--theme-color-gray-800);
}

.wrap {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: var(--theme-font-weight-semibold);
}

.variant--horizontal {
  align-items: center;
  flex-wrap: nowrap;
}
.variant--horizontal .wrap {
  margin-left: var(--theme-size-2);
}

.variant--vertical {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.variant--vertical .wrap {
  margin-top: var(--theme-size-2);
  justify-content: center;
}

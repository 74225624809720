.root {
  display: inline-block;
  border-radius: var(--theme-size-20);
  white-space: nowrap;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--theme-size-0-5);
}

.root svg {
  flex: none;
  display: block;
}

.variant--primary {
  background: var(--theme-color-gray-500);
  border-color: var(--theme-color-gray-500);
  color: white;
  border-width: 1px;
}

.variant--secondary {
  background: var(--theme-color-gray-100);
  border-color: var(--theme-color-gray-100);
  color: var(--theme-color-gray-800);
  border-width: 1px;
}

.variant--success {
  background: var(--theme-color-success);
  border-color: var(--theme-color-success);
  color: white;
  border-width: 1px;
}

.variant--danger {
  background: var(--theme-color-error);
  border-color: var(--theme-color-error);
  color: white;
  border-width: 1px;
}

.variant--frosted {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  color: white;
  border-width: 0;
}

.size--sm {
  min-width: var(--theme-size-4-5);
  padding: 0 var(--theme-size-1);
  font-size: var(--theme-font-size-xs);
}
.size--sm .wrap {
  height: var(--theme-size-4-5);
}

.size--md {
  min-width: var(--theme-size-6);
  font-size: var(--theme-font-size-sm);
  padding: 0 var(--theme-size-2-5);
}
.size--md .wrap {
  height: var(--theme-size-6);
}

.size--lg {
  min-width: var(--theme-size-8);
  padding: 0 var(--theme-size-3);
}
.size--lg .wrap {
  gap: var(--theme-size-1);
  height: var(--theme-size-8);
}

.size--xl {
  min-width: 44px;
  padding: var(--theme-size-2) var(--theme-size-4);
  font-weight: var(--theme-font-weight-semibold);
  text-transform: uppercase;
}
.size--xl .wrap {
  gap: var(--theme-size-2);
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--theme-size-5);
  color: var(--theme-color-gray-800);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border: 0;
  border-color: transparent;
  background-color: transparent;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}

.root:hover {
  background-color: var(--theme-color-gray-100);
  cursor: pointer;
}

.root::before {
  content: '';
  background-color: tansparent;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  transition: all 0.2s;
}

.icon {
  margin-right: var(--theme-size-5);
  width: var(--theme-size-5);
  height: auto;
  color: var(--theme-color-gray-400);
  transition: all 0.2s;
}

.isActive .icon {
  color: var(--theme-color-primary);
}

.root:hover .icon {
  color: var(--theme-color-primary);
}

.wrap {
  line-height: 1;
}

.label {
  display: block;
  font-weight: var(--theme-font-weight-semibold);
}

.hint {
  font-weight: var(--theme-font-weight-normal);
  font-size: var(--theme-font-size-xs);
  color: var(--theme-color-gray-400);
}

.variant--main {
  height: var(--theme-size-12);
  border-top-right-radius: var(--theme-border-radius-sm);
  border-bottom-right-radius: var(--theme-border-radius-sm);
}

.variant--main.isActive {
  color: var(--theme-color-gray-800);
  background-color: var(--theme-color-gray-100);
}

.variant--main.isActive::before {
  background-color: var(--theme-color-primary);
}

.variant--sub {
  height: var(--theme-size-10);
  border-radius: var(--theme-border-radius-sm);
}

.variant--sub.isActive {
  color: var(--theme-color-primary);
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.root {
  display: flex;
  color: var(--theme-color-gray-800);
  align-items: center;
  flex-wrap: nowrap;
  min-width: 0;
}

.wrap {
  overflow: hidden;
}

.name {
  font-weight: var(--theme-font-weight-semibold);
  overflow: hidden;
  text-overflow: ellipsis;
}

.size--md .wrap {
  margin-left: var(--theme-size-2);
}

.size--lg .name {
  font-size: var(--theme-font-size-lg);
}
.size--lg .wrap {
  margin-left: var(--theme-size-3);
}

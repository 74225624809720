.root {
  display: block;
  width: 100%;
  appearance: none;
  border-width: 1px;
  color: var(--theme-color-gray-800);
  border-color: var(--theme-color-gray-200);
  border-radius: var(--theme-border-radius-sm);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' width='18' height='18'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7' stroke='%23555555' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right var(--theme-size-2) top 50%, 0 0;
}

.root:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: var(--theme-color-primary);
}

.size--md,
.size--sm {
  padding: var(--theme-size-2);
}

.size--sm {
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
}

.isInvalid {
  --tw-border-opacity: 1;

  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.isDisabled {
  color: var(--theme-color-gray);
  border-style: dashed;
}

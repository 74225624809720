.root {
  height: 100vh;
  width: var(--theme-size-64);
  display: flex;
  z-index: 15;
  flex-shrink: 0;
  flex-direction: column;
  background-color: white;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
}

.footer {
  margin-top: auto;
  justify-content: center;
  padding: var(--theme-size-6) var(--theme-size-4);
  display: flex;
}

.header,
.footer {
  flex-shrink: 0;
}

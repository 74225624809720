.root {
  position: relative;
  width: 100%;
  border-radius: var(--theme-border-radius-md);
  background-color: white;
  margin: auto;
  box-shadow: var(--theme-shadow-xl);
  overflow: hidden;
  animation: popin 0.15s forwards;
}

.closed {
  animation: popout 0.15s forwards;
}
.root:focus {
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  animation: fadein 0.3s forwards;
  display: flex;
  padding: var(--theme-size-4);
  z-index: 98;
}

.overlay--closed {
  animation: fadeout 0.3s forwards;
}

.closeButton {
  position: absolute;
  right: var(--theme-size-2);
  top: var(--theme-size-2);
}

.title {
  font-size: var(--theme-font-size-xl);
  line-height: var(--theme-line-height-lg);
  font-family: var(--theme-font-family-heading);
  font-weight: var(--theme-font-weight-heading);
  color: var(--theme-color-gray-800);
}

.variant--centered .title {
  font-size: var(--theme-font-size-2xl);
  line-height: var(--theme-line-height-lx);
  text-align: center;
}

.description {
  margin-top: var(--theme-size-3);
  color: var(--theme-color-gray);
}

.variant--centered .description {
  text-align: center;
}

.header {
  padding: var(--theme-size-8) var(--theme-size-5) 0 var(--theme-size-5);
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .header {
    padding-left: var(--theme-size-8);
    padding-right: var(--theme-size-8);
  }
}

.empty {
  padding-bottom: var(--theme-size-8);
}

.body {
  padding: var(--theme-size-8) var(--theme-size-5);
}

@media (min-width: 768px) {
  .body {
    padding-left: var(--theme-size-8);
    padding-right: var(--theme-size-8);
  }
}

.footer {
  display: flex;
  flex-shrink: 0;
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
  padding: var(--theme-size-4) var(--theme-size-6);
  background-color: var(--theme-color-gray-50);
  color: var(--theme-color-gray);
  justify-content: center;
}

@media (min-width: 768px) {
  .footer {
    padding-left: var(--theme-size-8);
    padding-right: var(--theme-size-8);
  }
}

.size--xs {
  max-width: 22rem;
}

.size--sm {
  max-width: 26rem;
}

.size--md {
  max-width: 32rem;
}

.size--lg {
  max-width: 48rem;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes popin {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes popout {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

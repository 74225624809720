.root {
  position: relative;
  width: 100%;
}

.root::before {
  content: '';
  display: block;
  height: 1px;
  top: calc(50% - 1px);
  left: 0;
  right: 0;
  background-color: var(--theme-color-gray-200);
}

.label {
  position: absolute;
  left: 50%;
  top: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  padding-left: var(--theme-size-2);
  padding-right: var(--theme-size-2);
  background-color: white;
  text-transform: uppercase;
}

.root {
  position: absolute;
  right: calc(var(--theme-size-inverse) * var(--theme-size-1));
  top: 50%;
  transform: translate(100%, -50%);
  flex-shrink: 0;
  cursor: pointer;
  vertical-align: middle;
}

.root,
.root svg {
  width: var(--theme-size-4);
  height: var(--theme-size-4);
}

.root svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.root svg:first-child {
  bottom: 25%;
}

.root svg:last-child {
  top: 25%;
}

.root svg.isActive {
  color: var(--theme-color-gray-600);
}

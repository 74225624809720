.root {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  z-index: 1;
}

.position--topLeft .badge {
  top: 0;
  left: 0;
}

.position--topRight .badge {
  top: 0;
  right: 0;
}

.position--bottomLeft .badge {
  bottom: 0;
  left: 0;
}

.position--bottomRight .badge {
  bottom: 0;
  right: 0;
}

.position--centerRight .badge {
  right: 0;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
}

.position--centerLeft .badge {
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.root {
  display: block;
  margin-top: var(--theme-size-3);
  text-align: center;
  color: var(--theme-color-gray);
  pointer-events: none;
}

.link {
  color: var(--theme-color-primary);
  pointer-events: auto;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

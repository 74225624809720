.root {
  height: var(--theme-size-16);
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  position: relative;
  z-index: 2;
}

.menuToggle {
  flex-shrink: 0;
  margin-left: calc(var(--theme-size-inverse) * var(--theme-size-4));
}

.menuToggle svg {
  width: var(--theme-size-6);
  height: auto;
}

.logo {
  max-width: 150px;
}

.actions,
.trailing {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.actions {
  display: none;
  padding-left: var(--theme-size-4);
  flex: none;
}

.trailing {
  padding-left: var(--theme-size-4);
}

.variant--centered {
  position: relative;
}
.variant--centered .logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hasShadow {
  box-shadow: var(--theme-shadow-xs);
}

@media (min-width: 768px) {
  .actions {
    display: flex;
  }

  .menuToggle {
    display: none;
  }

  .actions + .trailing {
    margin-left: 0;
  }
}

.root {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: var(--theme-size-10);
}

.bg,
.dot {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.bg {
  position: absolute;
  display: block;
  width: var(--theme-size-6);
  height: var(--theme-size-6);
  border-radius: 9999px;
  background-color: white;
  border: 4px solid var(--theme-color-gray-200);
  appearance: none;
  outline: none;
  cursor: pointer;
  margin: 0;
}

.dot {
  display: block;
  overflow: hidden;
  height: var(--theme-size-6);
  border-radius: 9999px;
  background-color: var(--theme-color-gray-200);
  cursor: pointer;
}

.label {
  margin-left: var(--theme-size-2);
  color: var(--theme-color-gray);
}

.isChecked .bg {
  border-color: var(--theme-color-success);
  transform: translateX(var(--theme-size-4));
}

.isChecked .dot {
  background-color: var(--theme-color-success);
}

.isDisabled .dot {
  pointer-events: none;
  opacity: 0.5;
}

.root {
  position: relative;
  display: inline-block;
  flex: none;
  line-height: 0;
}

.root > div {
  border-radius: 9999px;
  overflow: hidden;
  background-color: var(--theme-color-gray-50);
}

.root img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.isPulsing::before {
  position: absolute;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  display: block;
  border: 2px solid var(--theme-color-primary);
  z-index: 2;
  border-radius: 9999px;
  box-sizing: border-box;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.size--xs > div {
  height: var(--theme-size-8);
  width: var(--theme-size-8);
}
.size--sm > div {
  height: var(--theme-size-9);
  width: var(--theme-size-9);
}
.size--md > div {
  height: var(--theme-size-10);
  width: var(--theme-size-10);
}
.size--lg > div {
  height: var(--theme-size-12);
  width: var(--theme-size-12);
}
.size--xl > div {
  height: var(--theme-size-20);
  width: var(--theme-size-20);
}
.size--2xl > div {
  height: var(--theme-size-24);
  width: var(--theme-size-24);
}
.size--3xl > div {
  height: var(--theme-size-28);
  width: var(--theme-size-28);
}
.size--4xl > div {
  height: var(--theme-size-34);
  width: var(--theme-size-34);
}

@keyframes ping {
  75%,
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

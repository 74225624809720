.root {
  position: relative;
  width: 100%;
}

.toggleButton {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.root {
}

.panel {
  position: fixed;
  z-index: 25;
  top: 0;
  height: 100vh;
  background-color: white;
  overflow-y: auto;
  box-shadow: var(--theme-shadow-md);
}

.position--left {
  left: 0;
  margin-right: var(--theme-size-6);
  animation: slideInLeft 0.3s forwards;
}

.position--right {
  right: 0;
  margin-left: var(--theme-size-6);
  animation: slideInRight 0.3s forwards;
}

.panel > div {
  position: relative;
  min-height: 100vh;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

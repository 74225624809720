.root {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.background {
  display: block;
  position: absolute;
  top: -50px;
  left: -50px;
  bottom: -50px;
  right: -50px;
  background-position: 50% 50%;
  background-size: 110%;
  filter: blur(15px);
  z-index: 0;
}

.image {
  position: relative;
}

.root {
  display: block;
  width: 100%;
  appearance: none;
  border: 1px solid var(--theme-color-gray-200);
  border-radius: var(--theme-border-radius-sm);
  font-size: var(--theme-font-size-md);
  line-height: var(--theme-line-height-md);
  color: var(--theme-color-gray-800);
}

.root:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: var(--theme-color-primary);
}
.root::placeholder {
  color: var(--theme-color-gray-400);
}

.size--xs,
.size--sm {
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
}

.size--xs {
  padding: 0 var(--theme-size-1);
  height: var(--theme-size-8);
}

.size--sm {
  padding: 0 var(--theme-size-2);
  height: var(--theme-size-9);
}

.size--md {
  padding: 0 var(--theme-size-2);
  height: var(--theme-size-10);
}

.size--lg {
  padding: 0 var(--theme-size-3);
  height: var(--theme-size-12);
}

.wrap {
  position: relative;
  width: 100%;
}

.icon {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: var(--theme-size-10);
  color: var(--theme-color-gray-400);
}

.iconPlacement--left .root {
  padding-left: var(--theme-size-10);
}
.iconPlacement--right .root {
  padding-right: var(--theme-size-10);
}

.iconPlacement--left .icon {
  left: 0;
}
.iconPlacement--right .icon {
  right: 0;
}

textarea.root {
  height: auto;
  resize: vertical;
}
textarea.size--xs {
  padding: var(--theme-size-1);
}
textarea.size--sm {
  padding: var(--theme-size-2);
}
textarea.size--md {
  padding: var(--theme-size-2);
}
textarea.size--lg {
  padding: var(--theme-size-3);
}

.root.isInvalid {
  --tw-border-opacity: 1;

  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.root.isReadOnly,
.root.isDisabled {
  color: var(--theme-color-gray);
  border-style: dashed;
}

.root.isDisabled {
  background-color: var(--theme-color-gray-50);
}

.root {
  border-radius: var(--theme-border-radius-full);
}

.variant--filled {
  background-color: white;
}
.variant--frosted {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  color: white;
  border-width: 0;
}

.variant--transparent {
  background-color: transparent;
}

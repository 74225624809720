.root {
  display: block;
  font-size: var(--theme-font-size-md);
  line-height: var(--theme-line-height-sm);
  color: var(--theme-color-gray);
}

.isRequired {
  color: var(--theme-color-error);
}

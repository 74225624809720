.root {
  overflow-x: auto;
  background-color: #fff;
}

.root table {
  width: 100%;
  border-spacing: 0;
}

.root th,
.root td {
  text-align: left;
  padding-right: var(--theme-size-3);
}

.root td {
  padding-top: var(--theme-size-3);
  padding-bottom: var(--theme-size-3);
}

.root th {
  padding-top: var(--theme-size-2);
  padding-bottom: var(--theme-size-2);
  font-family: var(--theme-font-family);
  font-weight: var(--theme-font-weight-heading);
  color: var(--theme-color-gray-400);
  font-size: var(--theme-font-size-xs);
  line-height: var(--theme-line-height-xs);
  text-transform: uppercase;
  white-space: nowrap;
}

.footer,
.root tbody tr ~ tr td {
  border-top: 1px solid var(--theme-color-gray-200);
}

.root tr th:last-child,
.root tr td:last-child {
  padding-right: 0;
}

.root tfoot tr td:last-child {
  text-align: right;
}

.fitContent {
  width: 1%;
  white-space: nowrap;
  text-align: center !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--theme-size-2) 0;
  color: var(--theme-color-gray-400);
}

.footer > div {
  line-height: var(--theme-line-height-sm);
  font-size: var(--theme-font-size-sm);
  display: flex;
  align-items: center;
}

.footer select {
  appearance: none;
  outline: none;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' width='18' height='18'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7' stroke='%23555555' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left 0 top 50%, 0 0;
  padding-left: var(--theme-size-6);
  margin-left: var(--theme-size-2);
  color: var(--theme-color-gray-800);
}

.footer p {
  margin-right: var(--theme-size-2);
}

.footer button {
  padding: var(--theme-size-1) !important;
}

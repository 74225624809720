.attributeDad:last-child {
  margin-bottom: var(--theme-size-4);
}

.attribute {
  display: flex;
  align-items: flex-start;
}

.attribute > div {
  flex: 1;
  margin-right: var(--theme-size-4);
  margin-bottom: 0;
}

.attribute > button {
  flex-shrink: 0;
  margin-right: 0;
}

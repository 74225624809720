.root {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.root input {
  display: none;
}

.root button {
  flex-shrink: 0;
}

.name {
  color: var(--theme-color-gray);
  font-size: var(--theme-font-size-sm);
  line-height: var(--theme-line-height-sm);
  margin-left: var(--theme-size-4);
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

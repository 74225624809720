.root {
  position: fixed;
  inset: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 20;
  animation: fadein 0.3s forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

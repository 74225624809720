.root {
  position: relative;
}

.wrap {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.isLoading > .wrap {
  opacity: 0.25;
}

.isLoading::after {
  position: absolute;
  inset: 0;
  cursor: wait;
  content: '';
}

.indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  animation: fadein 0.3s forwards;
}

.isOpaque.isLoading > .wrap {
  opacity: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  border: 0;
  border-color: transparent;
  background-color: transparent;
  outline: none;
  position: relative;
  padding: 0 var(--theme-size-6);
  display: inline-block;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  font-size: var(--theme-font-size-md);
  line-height: var(--theme-size-10);
  font-weight: var(--theme-font-weight-semibold);
  color: var(--theme-color-gray);
  transition: all 0.2s;
}

.root::before {
  display: block;
  content: attr(title);
  font-weight: var(--theme-font-weight-heading);
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.root::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--theme-color-gray-200);
  content: '';
  transition: all 0.2s;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--theme-size-1-5);
}

.icon {
  display: block;
  flex: none;
}

.isActive {
  font-weight: var(--theme-font-weight-heading);
  color: var(--theme-color-gray-800);
}
.isActive::after {
  height: 3px;
  background-color: var(--theme-color-primary);
}

.root {
  position: fixed;
  bottom: 0;
  padding: var(--theme-size-4);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  pointer-events: none;
}

.message {
  display: flex;
  align-items: center;
  border-radius: var(--theme-border-radius-lg);
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--theme-size-2);
  padding: var(--theme-size-2) var(--theme-size-4) var(--theme-size-2)
    var(--theme-size-3);
  animation: fadein 0.3s forwards;
}

.message:last-child {
  margin-bottom: 0;
}

.message p {
  margin-left: var(--theme-size-2);
}

.type--error {
  background-color: var(--theme-color-error);
}

.type--success {
  background-color: var(--theme-color-success);
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

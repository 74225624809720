.root {
  will-change: transform;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  z-index: 2;
  background-color: white;
  animation: fadein 0.3s forwards;
  box-shadow: var(--theme-shadow-xs);
}

.prevButton {
  left: 0;
  transform: translate(-50%, -50%);
}

.nextButton {
  right: 0;
  transform: translate(50%, -50%);
}

.root img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  pointer-events: none;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  position: relative;
  transition-property: height;
  overflow: hidden;
  will-change: height;
}

.root > div {
  position: absolute;
  inset: 0 0 auto 0;
}
